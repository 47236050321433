body, html {
    
    background-color:#262729;
    color: white;
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
    
    
}



 h1, h2 {
    color: white !important;   
}

h1 {
    padding-top: 50px !important;
    font-family: 'Alfa Slab One', cursive !important;
    font-size: 6rem !important;
}

hr {
    width: 35%;
    margin-top: 0;
}

h3.ui.center.aligned.header {
    margin-top: 0;
    
}

.value {
    margin: 0 !important;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: aliceblue;
    height: 55vh;
    color: aliceblue;
    background-image: url(./images/BasharFitness_Power-Stretch.jpeg) !important;
    
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-height: 50vh;
}

.nomargin {
    margin: 0 !important;

}

.sizingMT {
    font-size: 4em !important;
}
a {
    color: aliceblue;;
}

.cards {
    display: flex;
    justify-content: center;
    align-content: center;
    margin:  0 !important;
    padding-bottom: 25px !important;
}

.card {
    transition: all .3s ease-out;

}

.card:hover {
    transform: translate(0, -5px);
    transform: scale(1.1)
}

.background {
    background-image: url(./images/background.png) !important;
    
}


a:hover {
    color: #fbb216;
}

.value h3 {
    margin: 10px;
    
}
 
div img {
    background-size: contain;
}

 
.space {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    width: 250px !important;
    color: black !important;
    background-color: aliceblue;
}

.space:hover {
    background-color: #fbb216 !important;
}
 

.grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100px;    
}

.time-col {
    align-self: center;
  justify-self: center;
}

.logo-col {
    align-self: center;
  justify-self: center;
  margin-top: 5px;
}

.footer {
    border-top: 1px solid black;
    height: 100px; 
    margin-top: 25px;
    padding: 2em;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 500px) {

    div.ui.fluid.container {
       margin-left: 0 !important;
       margin-right: 0 !important;
    }
    h1 {
        font-size: 3rem !important;
        padding-top: 25px !important;
    }
    .value {
        height: 65vh; 
    }

    .content {
        max-height: fit-content; 
    }

    .sizingMT {
    font-size: 2em !important;
}

    .time-col {
        font-size: 1.5rem;
    }
    .footer p {
        font-size: .9rem;
    }

    
}